var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bone align",attrs:{"id":"zaim"}},[_c('div',{staticClass:"sec_3 contentBlock"},[_vm._m(0),_c('div',[_c('div',{class:{ clickable: _vm.canRepeat },on:{"click":function($event){return _vm.toRepeatLoan()}}},[_vm._m(1),_vm._m(2)]),_vm._m(3),_vm._m(4),_c('div',[_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.openGooglePlay()}}},[_c('img',{staticClass:"pic logoBox",attrs:{"alt":"","src":"img/finmall_box_logo.svg"}})]),_vm._m(5)]),_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.$router.push('/contacts')}}},[_vm._m(6),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Как быстро получить заём?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"pic konvert",attrs:{"alt":"","src":"img/konvert.webp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Отправьте заявку.")]),_c('p',[_vm._v("Заполните заявку и укажите ваши данные для отправки денег")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{staticClass:"pic clock",attrs:{"alt":"","src":"img/clock.webp"}})]),_c('div',[_c('h3',[_vm._v("Дождитесь рассмотрения.")]),_c('p',[_vm._v("Одобрение займет не более 2 минут")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{staticClass:"pic money",attrs:{"alt":"","src":"img/money.webp"}})]),_c('div',[_c('h3',[_vm._v("Получите деньги.")]),_c('p',[_vm._v("Сразу же после одобрения мы"),_c('br'),_vm._v(" отправим вам деньги на карту")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Установите приложение.")]),_c('p',[_vm._v("В приложении вам доступна вся информация по займу, а так же моментальное погашение")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"pic pcOnly callGirl",attrs:{"alt":"","src":"img/call_girl.webp"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Мы всегда на связи.")]),_c('p',[_vm._v("Если у вас есть вопросы - наша служба поддержки всегда на связи, по телефону или в чате")])])
}]

export { render, staticRenderFns }