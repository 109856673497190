<template>
  <div id="zaim" class="bone align">
    <div class="sec_3 contentBlock">
      <div class="title">
        <h3>Как быстро получить заём?</h3>
      </div>
      <div>
        <div :class="{ clickable: canRepeat }" @click="toRepeatLoan()">
          <div>
            <img alt="" class="pic konvert" src="img/konvert.webp" />
          </div>
          <div>
            <h3>Отправьте заявку.</h3>
            <p>Заполните заявку и укажите ваши данные для отправки денег</p>
          </div>
        </div>
        <div>
          <div>
            <img alt="" class="pic clock" src="img/clock.webp" />
          </div>
          <div>
            <h3>Дождитесь рассмотрения.</h3>
            <p>Одобрение займет не более 2 минут</p>
          </div>
        </div>
        <div>
          <div>
            <img alt="" class="pic money" src="img/money.webp" />
          </div>
          <div>
            <h3>Получите деньги.</h3>
            <p>Сразу же после одобрения мы<br> отправим вам деньги на карту</p>
          </div>
        </div>
        <div>
          <div class="clickable" @click="openGooglePlay()">
            <img alt="" class="pic logoBox" src="img/finmall_box_logo.svg" />
          </div>
          <div>
            <h3>Установите приложение.</h3>
            <p>В приложении вам доступна вся информация по займу, а так же моментальное погашение</p>
          </div>
        </div>
        <div class="clickable" @click="$router.push('/contacts')">
          <div>
            <img alt="" class="pic pcOnly callGirl" src="img/call_girl.webp" />
          </div>
          <div>
            <h3>Мы всегда на связи.</h3>
            <p>Если у вас есть вопросы - наша служба поддержки всегда на связи, по телефону или в чате</p>
          </div>
        </div>
      </div>
      <!--      <button class="dv_mobile mobileOnly" v-on:click="navigationEmit('zform', $event)">Получить деньги</button>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "how_to",
  computed: {
    ...mapGetters('repeatLoan', [
      'userCanBase',
      'userCanPdl'
    ]),
    canRepeat() {
      return this.userCanPdl || this.userCanBase
    }
  },
  methods: {
    toRepeatLoan() {
      if (this.canRepeat) {
        this.$router.push('/repeatLoan')
      }
    },
    openGooglePlay() {
      window.open("https://play.google.com/store/apps/details?id=ru.finmoll.customer")
    }
  }
}
</script>

<style scoped>
.clickable { cursor: pointer; }
.bone { position: relative }
.pic { display: none }
.sec_3 { }
.sec_3 > button { margin: 13.18vw 0 16.18vw 0; padding: 4.27vw 6.93vw; border-radius: 15px }
.sec_3 > h2 { padding-bottom: 3.47vw }
.sec_3 > div { padding-left: 6.93vw; position: relative; counter-increment: section }
.sec_3 > div:before { content: ""; display: block; width: 5.60vw; height: 75%; position: absolute; left: 1.87vw; top: 6.93vw; border-left: 2px dashed #1a5b43; }
.sec_3 > div > div > div { counter-increment: section }
.sec_3 > div > div { padding-bottom: 4.27vw }
.sec_3 > div > div > div > h3 { color: #0d5740; margin-right: 1.33vw; font-family: 'Lato' }
.sec_3 > div > div > div > p { display: inline; line-height: 5.60vw; color: #5B5C5C; }
.sec_3 > div > div:before { content: counter(section); width: 5.60vw; height: 5.60vw; background: #fff; border-radius: 6.93vw; border: 1px solid #e9efed; line-height: 5.60vw; padding-left: 0; padding-right: 0; color: #1a5b43; display: block; position: absolute; left: -0.83vw; font-weight: 700; text-align: center; }
@media (min-width: 760px) {
  .bone { padding: 0 }
  .pic { display: block; border-bottom: 4px solid rgba(26, 91, 67, 1); margin-bottom: 1.61vw; height: 8.06vw }
  .sec_3 { background-image: url(../../../public/img/Free-iPhone-Xs-Max-in-Female-Hand-Mockup-PSD_1.webp); background-size: contain;background-repeat: no-repeat; background-position-x: 112%; }
  .sec_3 > h2 { font-size: 2.58vw }
  .sec_3 > div { display: flex; flex-wrap: wrap; width: 58vw; align-items: center; padding-left: 0 }
  .sec_3 > div:before { display: none;}
  .sec_3 > div > div:before { display: none }
  .sec_3 > div > div { width: 21.94vw; margin-right: 2.42vw; height: 15.89vw }
  .sec_3 > div > div > div > h3 { display: block; margin-bottom: 0.65vw; font-size: 1.45vw }
  .sec_3 > div > div > div > p { display: block; line-height: 150% }
  .konvert { aspect-ratio: 119/88 }
  .clock { aspect-ratio: 86/101 }
  .money { aspect-ratio: 174/102 }
  .callGirl { aspect-ratio: 124/101 }
  .logoBox { aspect-ratio: 120/87 }
}
@media (min-width: 1240px) {
  .bone { padding: 0; background-position: center right }
  .pic { border-bottom: 4px solid rgba(26, 91, 67, 1); margin-bottom: 18px; height: 100px }
  .sec_3 {}
  .sec_3 > h2 { padding-bottom: 26px; font-size: 32px }
  .sec_3 > div { width: auto; padding-left: 0 }
  .sec_3 > div:before {}
  .sec_3 > div > div:before {  }
  .sec_3 > div > div { width: 272px; margin-right: 30px; padding-bottom: 43px; height: 197px }
  .sec_3 > div > div > div > h3 { margin-bottom: 8px; font-size: 18px }
  .sec_3 > div > div > div > p { line-height: 150% }
  /*.konvert { width: 135px }*/
  /*.clock { width: 85px }*/
  /*.money { width: 170px }*/
  /*.callGirl { width: 138px }*/
  /*.logoBox { width: 122px }*/


}
</style>